
const getJsonLdFileName = () => {
  const documentFileName = location.pathname.split('/').at(-1)
  if (documentFileName) {
    const documentFileNameWithoutExtension = documentFileName.split('.').at(0)
    if (documentFileNameWithoutExtension) {
      const jsonFileName = documentFileNameWithoutExtension + '.json'
      return jsonFileName
    }
  }
  return 'index.json'
}

class JsonLd extends HTMLScriptElement {
  #abortController = new AbortController()
  constructor () {
    super()
  }
  async connectedCallback () {
    const src = this.getAttribute('src') ?? getJsonLdFileName()
    const response = await fetch(src, {
      signal: this.#abortController.signal,
    })
    if (response.ok) {
      const json = await response.text()
      this.textContent = json
    } else {
      this.remove()
    }
  }
  disconnectedCallback () {
    this.#abortController.abort()
  }
}

customElements.define('json-ld', JsonLd, {
  extends: 'script',
})

declare global {
  interface HTMLElementTagNameMap {
    'json-ld': JsonLd
  }
}
